<template>
  <div class="page-404 page">
    <BreadcrumbsComponent title="Страница не найдена" />
    <div class="container-padding">
      <div class="page__inner page-404__inner">
        <div class="page-404__message">
          <span class="page-404__message-icon">
            <IconComponent name="pet" />
          </span>
          <span>
            Такой страницы пока не существует но мы подумаем как её использовать, а пока можете...
          </span>
        </div>
        <ProductsComponent :products="products" title="Посмотрите актуальные предложения" row />
        <router-link :to="{ name: 'home' }" class="btn btn--lg btn--gray-hollow">
          Вернуться на главную
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import IconComponent from "components/IconComponent.vue";
import NOT_FOUND_PAGE from "@/graphql/pages/NotFoundPage.graphql";
import ProductsComponent from "components/ProductsComponent.vue";

export default {
  components: { ProductsComponent, IconComponent, BreadcrumbsComponent },
  async asyncData({ res, apollo, store }) {
    await apollo.defaultClient
      .query({
        query: NOT_FOUND_PAGE,
      })
      .then(({ data }) => {
        store.state.page_404.products = data.products_paginate?.data;
        // дефолтные данные
        store.state.global.header_categories = data.header_categories;
        store.state.global.footer_categories = data.footer_categories;
        store.state.global.categories = data.categories;
        store.state.global.brands = data.brands;
        store.state.global.info = data.info || {};
        store.state.global.pages = data.pages;
        store.state.global.branches = data.filials;
      })
      .catch(() => {});
    if (res) {
      res.status(404);
    }
  },
  computed: {
    products() {
      return this.$store.state.page_404.products;
    },
  },
};
</script>

<style lang="stylus">
.page-404 {
  &__inner {
    gap: 80px

    .btn {
      align-self center
    }
  }

  &__message {
    max-width 670px
    margin 0 auto
    display flex
    flex-direction column
    align-items center
    gap: 30px
    font-size: 1.5em
    line-height: 28px;
    text-align: center;
    padding 50px 0 34px
    +below(480px) {
      font-size: 1.125em
      line-height: 24px;
      padding-bottom 0
    }

    &-icon {
      width: 150px
      height: 150px
      flex-shrink 0
      border-radius 50%
      background var(--white)
      display flex
      justify-content center
      align-items center

      .icon {
        width: 40px
        height: 40px
      }
    }
  }
}
</style>
